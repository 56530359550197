.follow-btn

    .btn
        @apply relative text-primary bg-white border-primary transition-all duration-300 ease-out

        &:hover
            @apply transform-gpu -translate-y-1 shadow-lg opacity-75
        &:active
            @apply transform-gpu translate-y-0 shadow-sm opacity-100

        span
            &:first-child
                @apply mr-1

                &::before
                    position: relative
                    display: inline-block
                    content: "\e939"
            
            &:last-child
                &::before
                    position: relative
                    display: inline-block
                    content: 'Ikuti'
                

    input
        
        &:checked
            
            + .btn
                @apply text-white bg-primary border-primary

                span
                    &:first-child
                        &::before
                            content: "\e910"
                    
                    &:last-child
                        &::before
                            content: 'Mengikuti'


