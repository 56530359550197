.story-card

    &__cover
        height: toRem(256)

        @media (min-width: theme('screens.lg'))
            height: toRem(296)
        
        @media (min-width: theme('screens.xl'))
            height: toRem(304)

    &__space
        height: toRem(76)

    &__title
        max-height: toRem(42)

        @media (min-width: theme('screens.lg'))
            max-height: toRem(48)

    &__stats

        li
            
            &:not(:last-child)
                margin-right: toRem(10)

            @media (max-width: 362px)
                &:not(:last-child)
                    margin-right: toRem(8)

                i, span
                    @apply text-xs

    // Breakpoint
    @media (min-width: theme('screens.sm'))
        
        &:hover

            .story-card__cover
                @apply transform-gpu -translate-y-2 shadow-lg opacity-75

    &--v2
        .story-card
            &__cover
                width: toRem(102)
                height: toRem(136)

                @media (min-width: theme('screens.lg'))
                    width: toRem(120)
                    height: toRem(160)

                // @media (min-width: theme('screens.lg'))
                //     width: auto
                //     height: toRem(296)
                
                // @media (min-width: theme('screens.xl'))
                //     height: toRem(384)