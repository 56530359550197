@import "var";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?1mjqna');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?1mjqna#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?1mjqna') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?1mjqna') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?1mjqna##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.rck {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ryd-align-center {
  &:before {
    content: $ryd-align-center; 
  }
}
.ryd-align-left {
  &:before {
    content: $ryd-align-left; 
  }
}
.ryd-align-right {
  &:before {
    content: $ryd-align-right; 
  }
}
.ryd-arrow-down {
  &:before {
    content: $ryd-arrow-down; 
  }
}
.ryd-arrow-right {
  &:before {
    content: $ryd-arrow-right; 
  }
}
.ryd-arrow-up {
  &:before {
    content: $ryd-arrow-up; 
  }
}
.ryd-back {
  &:before {
    content: $ryd-back; 
  }
}
.ryd-book {
  &:before {
    content: $ryd-book; 
  }
}
.ryd-bookmark {
  &:before {
    content: $ryd-bookmark; 
  }
}
.ryd-calendar {
  &:before {
    content: $ryd-calendar; 
  }
}
.ryd-card-bank {
  &:before {
    content: $ryd-card-bank; 
  }
}
.ryd-cerita {
  &:before {
    content: $ryd-cerita; 
  }
}
.ryd-close {
  &:before {
    content: $ryd-close; 
  }
}
.ryd-copy {
  &:before {
    content: $ryd-copy; 
  }
}
.ryd-eye-off {
  &:before {
    content: $ryd-eye-off; 
  }
}
.ryd-focus {
  &:before {
    content: $ryd-focus; 
  }
}
.ryd-follow {
  &:before {
    content: $ryd-follow; 
  }
}
.ryd-following {
  &:before {
    content: $ryd-following; 
  }
}
.ryd-heart-fill {
  &:before {
    content: $ryd-heart-fill; 
  }
}
.ryd-home {
  &:before {
    content: $ryd-home; 
  }
}
.ryd-info {
  &:before {
    content: $ryd-info; 
  }
}
.ryd-komentar {
  &:before {
    content: $ryd-komentar; 
  }
}
.ryd-konfirmasi {
  &:before {
    content: $ryd-konfirmasi; 
  }
}
.ryd-likes {
  &:before {
    content: $ryd-likes; 
  }
}
.ryd-list {
  &:before {
    content: $ryd-list; 
  }
}
.ryd-lock {
  &:before {
    content: $ryd-lock; 
  }
}
.ryd-logout {
  &:before {
    content: $ryd-logout; 
  }
}
.ryd-map {
  &:before {
    content: $ryd-map; 
  }
}
.ryd-min {
  &:before {
    content: $ryd-min; 
  }
}
.ryd-more {
  &:before {
    content: $ryd-more; 
  }
}
.ryd-notification {
  &:before {
    content: $ryd-notification; 
  }
}
.ryd-pencairan {
  &:before {
    content: $ryd-pencairan; 
  }
}
.ryd-plus {
  &:before {
    content: $ryd-plus; 
  }
}
.ryd-poin-pembaca {
  &:before {
    content: $ryd-poin-pembaca; 
  }
}
.ryd-poin-penulis {
  &:before {
    content: $ryd-poin-penulis; 
  }
}
.ryd-question {
  &:before {
    content: $ryd-question; 
  }
}
.ryd-rate {
  &:before {
    content: $ryd-rate; 
  }
}
.ryd-redo {
  &:before {
    content: $ryd-redo; 
  }
}
.ryd-ri_draft-line {
  &:before {
    content: $ryd-ri_draft-line; 
  }
}
.ryd-ri_youtube-line {
  &:before {
    content: $ryd-ri_youtube-line; 
  }
}
.ryd-search {
  &:before {
    content: $ryd-search; 
  }
}
.ryd-send {
  &:before {
    content: $ryd-send; 
  }
}
.ryd-setting {
  &:before {
    content: $ryd-setting; 
  }
}
.ryd-share {
  &:before {
    content: $ryd-share; 
  }
}
.ryd-shield {
  &:before {
    content: $ryd-shield; 
  }
}
.ryd-sort {
  &:before {
    content: $ryd-sort; 
  }
}
.ryd-star-filled {
  &:before {
    content: $ryd-star-filled; 
  }
}
.ryd-terbaru {
  &:before {
    content: $ryd-terbaru; 
  }
}
.ryd-terbaru-1 {
  &:before {
    content: $ryd-terbaru-1; 
  }
}
.ryd-terlama {
  &:before {
    content: $ryd-terlama; 
  }
}
.ryd-term {
  &:before {
    content: $ryd-term; 
  }
}
.ryd-text-bold {
  &:before {
    content: $ryd-text-bold; 
  }
}
.ryd-text-editor {
  &:before {
    content: $ryd-text-editor; 
  }
}
.ryd-text-image {
  &:before {
    content: $ryd-text-image; 
  }
}
.ryd-text-italic {
  &:before {
    content: $ryd-text-italic; 
  }
}
.ryd-text-underline {
  &:before {
    content: $ryd-text-underline; 
  }
}
.ryd-undo {
  &:before {
    content: $ryd-undo; 
  }
}
.ryd-user {
  &:before {
    content: $ryd-user; 
  }
}
.ryd-view {
  &:before {
    content: $ryd-view; 
  }
}
.ryd-wa {
  &:before {
    content: $ryd-wa; 
  }
}
.ryd-write {
  &:before {
    content: $ryd-write; 
  }
}
