.hero
    &__left

        h1
            max-width: toRem(648)


        &__btns
            .btn
                width: 100%
                max-width: toRem(160)  

                &:not(:last-child)
                    margin-right: toRem(15)

    &__right
        img
            width: toRem(219)

            @media (min-width: theme('screens.md'))
                width: toRem(400)

            @media (min-width: theme('screens.xl'))
                width: toRem(496)