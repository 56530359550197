.select2-container--default 
    .select2-selection--single,
    .select2-selection--multiple
        @apply h-12 bg-grey-light border border-transparent rounded px-4 py-3 duration-500 ease-out outline-none relative text-sm text-current flex items-center

        .select2-selection__rendered
            @apply pl-0 pr-6
            line-height: 1

        .select2-selection__arrow
            @apply hidden

        .select2-selection__choice
            @apply bg-primary bg-opacity-10 border-primary h-6

            .select2-selection__choice__remove
                height: toRem(22)
                border-right: 0
                padding-left: toRem(8)

                span
                    @apply text-grey

                &:hover
                    @apply bg-transparent transition-all

                    span
                        @apply text-danger


            .select2-selection__choice__display
                @apply text-primary
                line-height: toRem(22)
                padding-left: toRem(4)
                padding-right: toRem(10)

    &.select2-container--focus
        .select2-selection--multiple
            @apply border-transparent

// wrapper
.select2-container--open
    .select2-dropdown--below,
    .select2-dropdown--above
        @apply bg-white rounded px-2 py-4 border-0 shadow mt-2

        .select2-search--dropdown
            @apply p-0 shadow mb-2

            .select2-search__field
                @apply rounded bg-grey-light border-0 h-10 outline-none px-4

        .select2-results__option--selectable
            @apply rounded p-2

            &.select2-results__option--highlighted
                @apply bg-primary text-white

        .select2-results__option--selected
                @apply bg-primary bg-opacity-10 text-primary