.collapse

    &__head
        cursor: pointer

        i
            @apply transition-all

    // &__body

    &--expand

        .collapse
            &__head

                i
                    transform: rotate(180deg)

            // &__body