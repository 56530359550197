@layer utilities
    .fit-height
        // 100vh - navbars height - footer height
        min-height: calc(100vh - 3rem)

        @media (min-width: theme('screens.lg'))
            min-height: calc(100vh - 4rem - 5.25rem)


    .bg-skeleton
        @apply transition-all ease-out
        animation: bg-skeleton 2s cubic-bezier(0.4, 0, 0.6, 1) infinite

        @keyframes bg-skeleton
            0%,
            100%
                background-color: #cacbcc
    
            50%
                @apply bg-grey-light

    .bounce-btn
        display: inline-block
        animation: bounceBtn linear .75s
        animation-iteration-count: .75
        transform-origin: 50% 50%
        -webkit-animation: bounceBtn linear .75s
        -webkit-animation-iteration-count: .75
        -webkit-transform-origin: 50% 50%
        -moz-animation: bounceBtn linear .75s
        -moz-animation-iteration-count: .75
        -moz-transform-origin: 50% 50%
        -o-animation: bounceBtn linear .75s
        -o-animation-iteration-count: .75
        -o-transform-origin: 50% 50%
        -ms-animation: bounceBtn linear .75s
        -ms-animation-iteration-count: .75
        -ms-transform-origin: 50% 50%

    @keyframes bounceBtn
        0%
            transform:  rotate(0deg) scaleX(1.00) scaleY(1.00)

        10%
            transform:  rotate(0deg) scaleX(0.80) scaleY(0.80)

        20%
            transform:  rotate(0deg) scaleX(0.80) scaleY(0.80)

        30%
            transform:  rotate(0deg) scaleX(1.20) scaleY(1.20)

        50%
            transform:  rotate(0deg) scaleX(1.20) scaleY(1.20)

        60%
            transform:  rotate(0deg) scaleX(1.00) scaleY(1.00)

        100%
            transform:  rotate(0deg) scaleX(1.00) scaleY(1.00)


// @-moz-keyframes bounceBtn
//   0%
//     -moz-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00)

//   10%
//     -moz-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80)

//   20%
//     -moz-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80)

//   30%
//     -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   40%
//     -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   50%
//     -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   60%
//     -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   70%
//     -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   80%
//     -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   90%
//     -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   100%
//     -moz-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20)


// @-webkit-keyframes bounceBtn
//   0%
//     -webkit-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00)

//   10%
//     -webkit-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80)

//   20%
//     -webkit-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80)

//   30%
//     -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   40%
//     -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   50%
//     -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   60%
//     -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   70%
//     -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   80%
//     -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   90%
//     -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   100%
//     -webkit-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20)


// @-o-keyframes bounceBtn
//   0%
//     -o-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00)

//   10%
//     -o-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80)

//   20%
//     -o-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80)

//   30%
//     -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   40%
//     -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   50%
//     -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   60%
//     -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   70%
//     -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   80%
//     -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   90%
//     -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   100%
//     -o-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20)


// @-ms-keyframes bounceBtn
//   0%
//     -ms-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00)

//   10%
//     -ms-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80)

//   20%
//     -ms-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80)

//   30%
//     -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   40%
//     -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   50%
//     -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   60%
//     -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   70%
//     -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   80%
//     -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20)

//   90%
//     -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20)

//   100%
//     -ms-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20)
