.input,
.bootstrap-tagsinput
    @apply h-12 w-full bg-grey-light border border-transparent rounded px-4 py-3 duration-500 ease-out outline-none relative text-sm text-current
    transition-property: box-shadow

    &:focus
        @apply outline-none shadow

    &--succ
        @apply border-success

    &--sm
        @apply h-10 px-3 py-2

    &--thin-mobile
        @apply h-8 py-1 text-sm

        @media (min-width: theme('screens.lg'))
            @apply h-12 py-3 text-base

.bootstrap-tagsinput
    height: auto

    input
        @apply bg-transparent
        outline: none !important

    .tag.link-btn-primary
        @apply bg-primary
        padding: toRem(4) toRem(8)
        border-radius: toRem(4)
        color: white
        margin-right: toRem(4)
        margin-bottom: toRem(8)
        display: inline-flex
        align-items: center

        span
            width: toRem(10)
            height: toRem(10)
            margin-left: toRem(6)
            position: relative

            &::before,
            &::after
                @apply bg-danger
                display: block
                position: absolute
                content: ''
                width: toRem(10)
                height: toRem(2)
                top: 50%
                left: 50%
                border-radius: toRem(4)
            
            &::before
                transform: translate(-50%, -50%) rotate(45deg)
            
            &::after
                transform: translate(-50%, -50%) rotate(-45deg)
                    

textarea.input
    height: toRem(98)

.rb-nom
    @apply relative
    
    div
        @apply border border-grey-lightest rounded text-grey py-2.5 flex items-center justify-center transition-all ease-out

        i
            @apply mr-1 transition-all ease-out
        
        span
            @apply text-sm font-bold transition-all ease-out

    
    input
        @apply hidden

        &:checked
            + div
                @apply border-primary text-primary

.rb-payment
    @apply relative block
    
    div
        @apply relative border bg-grey-lightest bg-opacity-20 border-grey-lightest rounded text-grey py-2 px-2 flex items-center transition-all ease-out

        &::before,
        &::after
            @apply rounded-full transition-all ease-out
            display: block
            position: absolute
            content: ''
            top: 50%
            transform: translateY(-50%)

        &::before
            @apply border border-grey-lightest
            width: toRem(12)
            height: toRem(12)
            right: toRem(12)

        &::after
            @apply bg-transparent
            width: toRem(8)
            height: toRem(8)
            right: toRem(14)
        
        span
            @apply text-xs font-bold transition-all ease-out

    
    input
        @apply hidden

        &:checked
            + div
                @apply border-primary text-primary bg-primary bg-opacity-20

                &::before
                    @apply border border-primary

                &::after
                    @apply bg-primary

.cb
    @apply flex items-center mr-3
    > div
        span
            @apply block rounded w-6 h-6 border border-grey-lightest flex items-center justify-center text-white

            // i
            //     @apply text-white text-2xl
        
        input
            @apply hidden

            &:checked
                + span
                    @apply bg-primary border-primary

    > span
        @apply text-sm ml-3

.cb-label
    @apply rounded-full
    position: relative
    
    span
        @apply px-4 text-xs bg-grey-light inline-block text-current rounded-full
        height: toRem(26)
        line-height: toRem(26)
    
    input
        display: none

        &:checked
            + span
                @apply bg-primary text-white

    @media (min-width: theme('screens.sm'))
        @apply transition-all duration-300 ease-out overflow-hidden

        &:hover
            @apply transform-gpu -translate-y-1 shadow-lg opacity-75
        &:active
            @apply transform-gpu translate-y-0 shadow-sm opacity-100
    
    @media (min-width: theme('screens.lg'))

        span
            @apply text-sm
            height: toRem(30)
            line-height: toRem(30)
        

    
