.btn
    @apply inline-block flex justify-center items-center px-1 border font-bold rounded cursor-pointer

    img
        @apply h-6 mr-4

    &__lg-round
        @apply rounded-lg

    &__sm
        @apply h-8

    &__md
        @apply h-10

    &__lg
        @apply h-12 
        
        @media (max-width: 362px)
            @apply text-sm

    &__primary
        @apply text-white bg-primary border-primary

    &__secondary
        @apply text-white bg-accent-1 border-accent-1

    &__warning
        @apply text-white bg-warning border-warning
        
    &__vanilla
        @apply text-grey border-transparent rounded-none

    &__primary-o
        @apply text-primary bg-white border-primary

    &__secondary-o
        @apply text-accent-1 bg-white border-accent-1

    &__warning-o
        @apply text-warning bg-white border-warning

    &__fb
        @apply text-white bg-fb border-fb

    &__google
        @apply text-current-light bg-white border-bdr-google

    &:disabled
        @apply text-grey bg-disable border-disable cursor-not-allowed

    // Breakpoint
    @media (min-width: theme('screens.sm'))
        @apply transition-all duration-300 ease-out

        &:hover
            @apply transform-gpu -translate-y-1 shadow-lg opacity-75
        &:active
            @apply transform-gpu translate-y-0 shadow-sm opacity-100