@layer base
    *
        -webkit-tap-highlight-color: transparent

    html
        @apply bg-grey-light    

    body
        @apply text-grey

    button
        outline: none

        &:active,
        &:hover
            outline: none

    main
        overflow-x: hidden

    select
        cursor: pointer
        -webkit-appearance: none
        -moz-appearance: none
        text-indent: 1px
        text-overflow: ''

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

    input[type=number]
        -moz-appearance: textfield

    .subtitle
        max-width: toRem(802)

    .noselect
        -webkit-touch-callout: none
        -webkit-user-select: none
        -khtml-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none

    .link
        // Breakpoint
        @media (min-width: theme('screens.sm'))
            @apply transition-all duration-300 ease-out

            &:hover
                @apply transform-gpu opacity-60
            &:active
                @apply transform-gpu opacity-100

    article
        p
            @apply text-sm
            margin-bottom: toRem(24)

            @media (min-width: theme('screens.lg'))
                @apply text-base
                margin-bottom: toRem(32)
            