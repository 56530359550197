.rating
    display: inline-block
    position: relative

.rating label
    position: absolute
    top: 0
    left: 0
    height: 100%
    cursor: pointer

.rating label:last-child
    position: static

.rating label:nth-child(1)
    z-index: 5

.rating label:nth-child(2)
    z-index: 4

.rating label:nth-child(3)
    z-index: 3

.rating label:nth-child(4)
    z-index: 2

.rating label:nth-child(5)
    z-index: 1

.rating label input
    display: none

.rating label .rck
    @apply mx-1
    font-size: toRem(40)
    float: left
    color: transparent

.rating label:last-child .rck
    @apply text-grey opacity-40

.rating:not(:hover) label input:checked ~ .rck,
.rating:hover label:hover input ~ .rck
    @apply text-warning opacity-100
