@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  * {
    -webkit-tap-highlight-color: transparent;
  }

  html {
    @apply bg-grey-light;
  }

  body {
    @apply text-grey;
  }

  button {
    outline: none;
  }
  button:active, button:hover {
    outline: none;
  }

  main {
    overflow-x: hidden;
  }

  select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .subtitle {
    max-width: 50.125rem;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (min-width: theme("screens.sm")) {
    .link {
      @apply transition-all duration-300 ease-out;
    }
    .link:hover {
      @apply transform-gpu opacity-60;
    }
    .link:active {
      @apply transform-gpu opacity-100;
    }
  }

  article p {
    @apply text-sm;
    margin-bottom: 1.5rem;
  }
  @media (min-width: theme("screens.lg")) {
    article p {
      @apply text-base;
      margin-bottom: 2rem;
    }
  }
}
@layer utilities {
  .fit-height {
    min-height: calc(100vh - 3rem);
  }
  @media (min-width: theme("screens.lg")) {
    .fit-height {
      min-height: calc(100vh - 4rem - 5.25rem);
    }
  }

  .bg-skeleton {
    @apply transition-all ease-out;
    animation: bg-skeleton 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  @keyframes bg-skeleton {
    0%, 100% {
      background-color: #cacbcc;
    }
    50% {
      @apply bg-grey-light;
    }
  }

  .bounce-btn {
    display: inline-block;
    animation: bounceBtn linear 0.75s;
    animation-iteration-count: 0.75;
    transform-origin: 50% 50%;
    -webkit-animation: bounceBtn linear 0.75s;
    -webkit-animation-iteration-count: 0.75;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: bounceBtn linear 0.75s;
    -moz-animation-iteration-count: 0.75;
    -moz-transform-origin: 50% 50%;
    -o-animation: bounceBtn linear 0.75s;
    -o-animation-iteration-count: 0.75;
    -o-transform-origin: 50% 50%;
    -ms-animation: bounceBtn linear 0.75s;
    -ms-animation-iteration-count: 0.75;
    -ms-transform-origin: 50% 50%;
  }

  @keyframes bounceBtn {
    0% {
      transform: rotate(0deg) scaleX(1) scaleY(1);
    }
    10% {
      transform: rotate(0deg) scaleX(0.8) scaleY(0.8);
    }
    20% {
      transform: rotate(0deg) scaleX(0.8) scaleY(0.8);
    }
    30% {
      transform: rotate(0deg) scaleX(1.2) scaleY(1.2);
    }
    50% {
      transform: rotate(0deg) scaleX(1.2) scaleY(1.2);
    }
    60% {
      transform: rotate(0deg) scaleX(1) scaleY(1);
    }
    100% {
      transform: rotate(0deg) scaleX(1) scaleY(1);
    }
  }
}
@font-face {
  font-family: "Custom-Icon-by-Ryaanst";
  src: url("../fonts/Custom-Icon-by-Ryaanst.eot?1mjqna");
  src: url("../fonts/Custom-Icon-by-Ryaanst.eot?1mjqna#iefix") format("embedded-opentype"), url("../fonts/Custom-Icon-by-Ryaanst.ttf?1mjqna") format("truetype"), url("../fonts/Custom-Icon-by-Ryaanst.woff?1mjqna") format("woff"), url("../fonts/Custom-Icon-by-Ryaanst.svg?1mjqna#Custom-Icon-by-Ryaanst") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.rck {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Custom-Icon-by-Ryaanst" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ryd-align-center:before {
  content: "\e900";
}

.ryd-align-left:before {
  content: "\e901";
}

.ryd-align-right:before {
  content: "\e902";
}

.ryd-arrow-down:before {
  content: "\e903";
}

.ryd-arrow-right:before {
  content: "\e904";
}

.ryd-arrow-up:before {
  content: "\e905";
}

.ryd-back:before {
  content: "\e906";
}

.ryd-book:before {
  content: "\e907";
}

.ryd-bookmark:before {
  content: "\e908";
}

.ryd-calendar:before {
  content: "\e909";
}

.ryd-card-bank:before {
  content: "\e90a";
}

.ryd-cerita:before {
  content: "\e90b";
}

.ryd-close:before {
  content: "\e90c";
}

.ryd-copy:before {
  content: "\e90d";
}

.ryd-eye-off:before {
  content: "\e90e";
}

.ryd-focus:before {
  content: "\e90f";
}

.ryd-follow:before {
  content: "\e910";
}

.ryd-following:before {
  content: "\e911";
}

.ryd-heart-fill:before {
  content: "\e912";
}

.ryd-home:before {
  content: "\e913";
}

.ryd-info:before {
  content: "\e914";
}

.ryd-komentar:before {
  content: "\e915";
}

.ryd-konfirmasi:before {
  content: "\e916";
}

.ryd-likes:before {
  content: "\e917";
}

.ryd-list:before {
  content: "\e918";
}

.ryd-lock:before {
  content: "\e919";
}

.ryd-logout:before {
  content: "\e91a";
}

.ryd-map:before {
  content: "\e91b";
}

.ryd-min:before {
  content: "\e91c";
}

.ryd-more:before {
  content: "\e91d";
}

.ryd-notification:before {
  content: "\e91e";
}

.ryd-pencairan:before {
  content: "\e91f";
}

.ryd-plus:before {
  content: "\e920";
}

.ryd-poin-pembaca:before {
  content: "\e921";
}

.ryd-poin-penulis:before {
  content: "\e922";
}

.ryd-question:before {
  content: "\e923";
}

.ryd-rate:before {
  content: "\e924";
}

.ryd-redo:before {
  content: "\e925";
}

.ryd-ri_draft-line:before {
  content: "\e926";
}

.ryd-ri_youtube-line:before {
  content: "\e927";
}

.ryd-search:before {
  content: "\e928";
}

.ryd-send:before {
  content: "\e929";
}

.ryd-setting:before {
  content: "\e92a";
}

.ryd-share:before {
  content: "\e92b";
}

.ryd-shield:before {
  content: "\e92c";
}

.ryd-sort:before {
  content: "\e92d";
}

.ryd-star-filled:before {
  content: "\e92e";
}

.ryd-terbaru:before {
  content: "\e92f";
}

.ryd-terbaru-1:before {
  content: "\e930";
}

.ryd-terlama:before {
  content: "\e931";
}

.ryd-term:before {
  content: "\e932";
}

.ryd-text-bold:before {
  content: "\e933";
}

.ryd-text-editor:before {
  content: "\e934";
}

.ryd-text-image:before {
  content: "\e935";
}

.ryd-text-italic:before {
  content: "\e936";
}

.ryd-text-underline:before {
  content: "\e937";
}

.ryd-undo:before {
  content: "\e938";
}

.ryd-user:before {
  content: "\e939";
}

.ryd-view:before {
  content: "\e93a";
}

.ryd-wa:before {
  content: "\e93b";
}

.ryd-write:before {
  content: "\e93c";
}

.input,
.bootstrap-tagsinput {
  @apply h-12 w-full bg-grey-light border border-transparent rounded px-4 py-3 duration-500 ease-out outline-none relative text-sm text-current;
  transition-property: box-shadow;
}
.input:focus,
.bootstrap-tagsinput:focus {
  @apply outline-none shadow;
}
.input--succ,
.bootstrap-tagsinput--succ {
  @apply border-success;
}
.input--sm,
.bootstrap-tagsinput--sm {
  @apply h-10 px-3 py-2;
}
.input--thin-mobile,
.bootstrap-tagsinput--thin-mobile {
  @apply h-8 py-1 text-sm;
}
@media (min-width: theme("screens.lg")) {
  .input--thin-mobile,
.bootstrap-tagsinput--thin-mobile {
    @apply h-12 py-3 text-base;
  }
}

.bootstrap-tagsinput {
  height: auto;
}
.bootstrap-tagsinput input {
  @apply bg-transparent;
  outline: none !important;
}
.bootstrap-tagsinput .tag.link-btn-primary {
  @apply bg-primary;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: white;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
  display: inline-flex;
  align-items: center;
}
.bootstrap-tagsinput .tag.link-btn-primary span {
  width: 0.625rem;
  height: 0.625rem;
  margin-left: 0.375rem;
  position: relative;
}
.bootstrap-tagsinput .tag.link-btn-primary span::before, .bootstrap-tagsinput .tag.link-btn-primary span::after {
  @apply bg-danger;
  display: block;
  position: absolute;
  content: "";
  width: 0.625rem;
  height: 0.125rem;
  top: 50%;
  left: 50%;
  border-radius: 0.25rem;
}
.bootstrap-tagsinput .tag.link-btn-primary span::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.bootstrap-tagsinput .tag.link-btn-primary span::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

textarea.input {
  height: 6.125rem;
}

.rb-nom {
  @apply relative;
}
.rb-nom div {
  @apply border border-grey-lightest rounded text-grey py-2.5 flex items-center justify-center transition-all ease-out;
}
.rb-nom div i {
  @apply mr-1 transition-all ease-out;
}
.rb-nom div span {
  @apply text-sm font-bold transition-all ease-out;
}
.rb-nom input {
  @apply hidden;
}
.rb-nom input:checked + div {
  @apply border-primary text-primary;
}

.rb-payment {
  @apply relative block;
}
.rb-payment div {
  @apply relative border bg-grey-lightest bg-opacity-20 border-grey-lightest rounded text-grey py-2 px-2 flex items-center transition-all ease-out;
}
.rb-payment div::before, .rb-payment div::after {
  @apply rounded-full transition-all ease-out;
  display: block;
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
}
.rb-payment div::before {
  @apply border border-grey-lightest;
  width: 0.75rem;
  height: 0.75rem;
  right: 0.75rem;
}
.rb-payment div::after {
  @apply bg-transparent;
  width: 0.5rem;
  height: 0.5rem;
  right: 0.875rem;
}
.rb-payment div span {
  @apply text-xs font-bold transition-all ease-out;
}
.rb-payment input {
  @apply hidden;
}
.rb-payment input:checked + div {
  @apply border-primary text-primary bg-primary bg-opacity-20;
}
.rb-payment input:checked + div::before {
  @apply border border-primary;
}
.rb-payment input:checked + div::after {
  @apply bg-primary;
}

.cb {
  @apply flex items-center mr-3;
}
.cb > div span {
  @apply block rounded w-6 h-6 border border-grey-lightest flex items-center justify-center text-white;
}
.cb > div input {
  @apply hidden;
}
.cb > div input:checked + span {
  @apply bg-primary border-primary;
}
.cb > span {
  @apply text-sm ml-3;
}

.cb-label {
  @apply rounded-full;
  position: relative;
}
.cb-label span {
  @apply px-4 text-xs bg-grey-light inline-block text-current rounded-full;
  height: 1.625rem;
  line-height: 1.625rem;
}
.cb-label input {
  display: none;
}
.cb-label input:checked + span {
  @apply bg-primary text-white;
}
@media (min-width: theme("screens.sm")) {
  .cb-label {
    @apply transition-all duration-300 ease-out overflow-hidden;
  }
  .cb-label:hover {
    @apply transform-gpu -translate-y-1 shadow-lg opacity-75;
  }
  .cb-label:active {
    @apply transform-gpu translate-y-0 shadow-sm opacity-100;
  }
}
@media (min-width: theme("screens.lg")) {
  .cb-label span {
    @apply text-sm;
    height: 1.875rem;
    line-height: 1.875rem;
  }
}

.btn {
  @apply inline-block flex justify-center items-center px-1 border font-bold rounded cursor-pointer;
}
.btn img {
  @apply h-6 mr-4;
}
.btn__lg-round {
  @apply rounded-lg;
}
.btn__sm {
  @apply h-8;
}
.btn__md {
  @apply h-10;
}
.btn__lg {
  @apply h-12;
}
@media (max-width: 362px) {
  .btn__lg {
    @apply text-sm;
  }
}
.btn__primary {
  @apply text-white bg-primary border-primary;
}
.btn__secondary {
  @apply text-white bg-accent-1 border-accent-1;
}
.btn__warning {
  @apply text-white bg-warning border-warning;
}
.btn__vanilla {
  @apply text-grey border-transparent rounded-none;
}
.btn__primary-o {
  @apply text-primary bg-white border-primary;
}
.btn__secondary-o {
  @apply text-accent-1 bg-white border-accent-1;
}
.btn__warning-o {
  @apply text-warning bg-white border-warning;
}
.btn__fb {
  @apply text-white bg-fb border-fb;
}
.btn__google {
  @apply text-current-light bg-white border-bdr-google;
}
.btn:disabled {
  @apply text-grey bg-disable border-disable cursor-not-allowed;
}
@media (min-width: theme("screens.sm")) {
  .btn {
    @apply transition-all duration-300 ease-out;
  }
  .btn:hover {
    @apply transform-gpu -translate-y-1 shadow-lg opacity-75;
  }
  .btn:active {
    @apply transform-gpu translate-y-0 shadow-sm opacity-100;
  }
}

@media (min-width: theme("screens.sm")) {
  .navigations button {
    @apply transition-all duration-300 ease-out;
  }
  .navigations button:hover {
    @apply transform-gpu -translate-y-1 shadow-lg opacity-75;
  }
  .navigations button:active {
    @apply transform-gpu translate-y-0 shadow-sm opacity-100;
  }
}

.notif-badge::after {
  @apply bg-transparent transition-all duration-300 ease-bounce;
  content: "";
  display: block;
  position: absolute;
  width: 0rem;
  height: 0rem;
  border-radius: 50%;
  transform-origin: center;
  top: 0;
  right: 0;
}
.notif-badge--active::before {
  @apply bg-danger animate-ping;
  content: "";
  display: block;
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  transform-origin: center;
  top: 0;
  right: 0;
}
.notif-badge--active::after {
  @apply bg-danger;
  width: 0.5rem;
  height: 0.5rem;
}

.otp-input {
  @apply border-b-2 border-grey text-primary text-2xl font-bold py-2;
}
.otp-input:focus {
  @apply border-primary;
}
.otp-input:not(:placeholder-shown) {
  @apply border-primary;
}
.otp-input--err {
  @apply text-danger;
}
.otp-input--err:focus {
  @apply border-danger;
}
.otp-input--err:not(:placeholder-shown) {
  @apply border-danger;
}

.love-button i::before {
  @appy transition-all;
  content: "\e917";
}
.love-button input:checked + i::before {
  @apply text-danger;
  content: "\e912";
}

.bookmark-btn input:checked + span {
  @apply bg-primary text-white;
}

.follow-btn .btn {
  @apply relative text-primary bg-white border-primary transition-all duration-300 ease-out;
}
.follow-btn .btn:hover {
  @apply transform-gpu -translate-y-1 shadow-lg opacity-75;
}
.follow-btn .btn:active {
  @apply transform-gpu translate-y-0 shadow-sm opacity-100;
}
.follow-btn .btn span:first-child {
  @apply mr-1;
}
.follow-btn .btn span:first-child::before {
  position: relative;
  display: inline-block;
  content: "\e939";
}
.follow-btn .btn span:last-child::before {
  position: relative;
  display: inline-block;
  content: "Ikuti";
}
.follow-btn input:checked + .btn {
  @apply text-white bg-primary border-primary;
}
.follow-btn input:checked + .btn span:first-child::before {
  content: "\e910";
}
.follow-btn input:checked + .btn span:last-child::before {
  content: "Mengikuti";
}

.sub-header {
  @apply fixed top-0 w-full;
  z-index: 49;
}
.sub-header .scroll-indicator {
  @apply opacity-0;
}
.input-group__label {
  @apply block text-sm text-grey pb-1;
}
.input-group__icon, .input-group__icon2 {
  @apply absolute text-current;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.375rem;
  z-index: 1;
}
.input-group__icon--sm, .input-group__icon2--sm {
  font-size: 1rem;
}
.input-group sup {
  @apply mt-2 block top-0 text-grey;
  font-size: 0.75rem;
  line-height: 1;
}
.input-group--i-left .input-group__icon {
  left: 0.9375rem;
}
.input-group--i-left .input-group__icon--sm {
  left: 0.75rem;
}
.input-group--i-left .input {
  padding-left: 2.75rem;
}
.input-group--i-left .input--sm {
  padding-left: 2.375rem;
}
.input-group--i-right .input-group__icon {
  right: 0.9375rem;
}
.input-group--i-right .input-group__icon--sm {
  right: 0.75rem;
}
.input-group--i-right .input {
  padding-right: 2.75rem;
}
.input-group--i-right .input--sm {
  padding-right: 2.375rem;
}
.input-group--i-both .input-group__icon2 {
  right: 0.9375rem;
}
.input-group--i-both .input-group__icon2--sm {
  right: 0.75rem;
}
.input-group--i-both .input-group__icon {
  left: 0.9375rem;
}
.input-group--i-both .input-group__icon--sm {
  left: 0.75rem;
}
.input-group--i-both .input {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
}
.input-group--i-both .input--sm {
  padding-right: 2.375rem;
  padding-left: 2.375rem;
}
.input-group--err .input {
  @apply border-danger;
}
.input-group--err sup {
  @apply text-danger;
}

label.checkbox {
  @apply flex justify-center;
}
label.checkbox span::before {
  @apply h-6 w-6 rounded-sm;
}
label.checkbox input[type=checkbox] {
  @apply hidden bg-grey-light;
}
label.checkbox input[type=checkbox]:checked + span::before {
  @apply bg-primary;
}

.story-header {
  @apply bg-no-repeat bg-cover bg-center;
  height: 38vw;
  min-height: 19.125rem;
  background-color: #454E65;
  background-image: url("../img/cover-orn.png");
}
.story-header--detail {
  @apply relative;
}
.story-header--detail::after {
  @apply block absolute w-full h-full top-0 left-0;
  content: "";
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) -11.11%, rgba(0, 0, 0, 0.61) 22.81%);
}

.story-info {
  @apply relative bg-white rounded-t-3xl;
}
.story-info__cover {
  width: 12.1875rem;
  height: 16.25rem;
  top: -9.125rem;
  margin-bottom: -9.125rem;
}
@media (min-width: theme("screens.lg")) {
  .story-info__cover {
    margin-bottom: 0;
    top: 0;
  }
}
.story-info__section__btn {
  grid-template-columns: auto 3rem;
}

.story-part {
  @apply block py-4 border-b border-solid border-grey border-opacity-20 relative;
}
.story-part__subtitle {
  @apply text-xs;
}
.story-part__title {
  @apply font-medium mt-1 pr-5 line-clamp-1 transition-all duration-300;
}
.story-part::after {
  font-family: "Custom-Icon-by-Ryaanst" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  position: absolute;
  right: 0;
  top: 50%;
  @apply transform -translate-y-1/2 text-2xl transition-all duration-300;
}
.story-part--unread .story-part__title {
  @apply text-current;
}
.story-part--unread::after {
  @apply text-current;
}
@media (min-width: theme("screens.sm")) {
  .story-part {
    @apply transition-all duration-300 ease-out;
  }
  .story-part:hover {
    @apply pl-2;
  }
  .story-part:hover::after {
    @apply text-primary;
  }
  .story-part:hover .story-part__title {
    @apply text-primary;
  }
}

.category-card {
  background-size: cover;
  height: 8.0625rem;
}
@media (min-width: theme("screens.sm")) {
  .category-card {
    background-size: 100%;
  }
  .category-card:hover {
    background-size: 110%;
  }
}
@media (min-width: theme("screens.md")) {
  .category-card {
    height: 11.25rem;
  }
}
@media (min-width: theme("screens.lg")) {
  .category-card {
    background-size: 130%;
  }
  .category-card:hover {
    background-size: 140%;
  }
}
@media (min-width: theme("screens.xl")) {
  .category-card {
    background-size: 134%;
  }
  .category-card:hover {
    background-size: 144%;
  }
}
.category-card::after {
  @apply absolute block w-full h-full top-0 left-0;
  content: "";
  background: linear-gradient(0deg, #1E2023 8.14%, rgba(43, 46, 51, 0) 112.79%);
}
.category-card label {
  height: 1.625rem;
  line-height: 1.625rem;
}
@media (min-width: theme("screens.lg")) {
  .category-card label {
    height: 1.8125rem;
    line-height: 1.8125rem;
  }
}
.category-card p {
  max-width: 24.125rem;
}

.story-card__cover {
  height: 16rem;
}
@media (min-width: theme("screens.lg")) {
  .story-card__cover {
    height: 18.5rem;
  }
}
@media (min-width: theme("screens.xl")) {
  .story-card__cover {
    height: 19rem;
  }
}
.story-card__space {
  height: 4.75rem;
}
.story-card__title {
  max-height: 2.625rem;
}
@media (min-width: theme("screens.lg")) {
  .story-card__title {
    max-height: 3rem;
  }
}
.story-card__stats li:not(:last-child) {
  margin-right: 0.625rem;
}
@media (max-width: 362px) {
  .story-card__stats li:not(:last-child) {
    margin-right: 0.5rem;
  }
  .story-card__stats li i, .story-card__stats li span {
    @apply text-xs;
  }
}
@media (min-width: theme("screens.sm")) {
  .story-card:hover .story-card__cover {
    @apply transform-gpu -translate-y-2 shadow-lg opacity-75;
  }
}
.story-card--v2 .story-card__cover {
  width: 6.375rem;
  height: 8.5rem;
}
@media (min-width: theme("screens.lg")) {
  .story-card--v2 .story-card__cover {
    width: 7.5rem;
    height: 10rem;
  }
}

.hero-slider-card {
  @apply relative overflow-hidden flex flex-col justify-end;
}
.hero-slider-card::before {
  @apply block absolute left-0 top-0 w-full h-full;
  content: "";
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0.61) 71.8%);
}
.hero-slider-card label {
  height: 1.625rem;
  line-height: 1.625rem;
}
@media (min-width: theme("screens.md")) {
  .hero-slider-card label {
    height: 2rem;
    line-height: 2rem;
  }
}
.hero-slider-card p {
  max-width: 70%;
}

.swiper-pagination .swiper-pagination-bullet {
  @apply w-1 h-1 bg-grey opacity-100 transition-all duration-300 ease-out;
  margin: 0 0.125rem !important;
  border-radius: 6.25rem;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-primary;
  width: 0.875rem;
}

.popup {
  @apply fixed top-0 left-0 w-full h-full bg-transparent invisible;
  transition: all 0.2s ease-out 0.2s;
  z-index: 999;
}
.popup__card {
  @apply bg-white absolute w-full;
  transition: all 0.4s ease-out 0s;
  bottom: -100%;
}
@media (min-width: theme("screens.lg")) {
  .popup__card {
    right: 50%;
    transform: translate(50%, 50%);
    max-width: 25rem;
  }
}
.popup__card__head {
  @apply px-5;
}
.popup__card__head ._inner {
  @apply py-4 flex justify-between items-center;
}
.popup__card__head ._inner._v2 {
  @apply pb-2;
}
.popup__card__head ._inner span {
  @apply text-current font-medium;
}
.popup__card__head ._inner button i {
  @apply text-2xl;
}
.popup__card__body {
  @apply px-5;
}
.popup--drawer .popup__card {
  height: 100vh;
  bottom: inherit;
  right: -100%;
}
.popup--show {
  @apply bg-current-light bg-opacity-80 visible;
  transition: all 0.2s ease-out 0s;
}
.popup--show .popup__card {
  max-height: 70vh;
  overflow-y: auto;
  bottom: 0%;
  transition: all 0.4s ease-out 0.1s;
}
@media (min-width: theme("screens.lg")) {
  .popup--show .popup__card {
    bottom: 50%;
    right: 50%;
    max-width: 30rem;
    transform: translate(50%, 50%);
    border-radius: 0.5rem;
  }
}
.popup--show.popup--drawer .popup__card {
  max-height: 100vh;
  right: 0%;
}

.popup-share {
  @apply pt-7 pb-9 grid grid-cols-4 gap-y-6 gap-x-2;
}
.popup-share li > * {
  @apply block text-center;
}
.popup-share li > * img {
  @apply h-10 mx-auto mb-2;
}
.popup-share li > * span {
  @apply text-xs block;
}

.rating {
  display: inline-block;
  position: relative;
}

.rating label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}

.rating label:last-child {
  position: static;
}

.rating label:nth-child(1) {
  z-index: 5;
}

.rating label:nth-child(2) {
  z-index: 4;
}

.rating label:nth-child(3) {
  z-index: 3;
}

.rating label:nth-child(4) {
  z-index: 2;
}

.rating label:nth-child(5) {
  z-index: 1;
}

.rating label input {
  display: none;
}

.rating label .rck {
  @apply mx-1;
  font-size: 2.5rem;
  float: left;
  color: transparent;
}

.rating label:last-child .rck {
  @apply text-grey opacity-40;
}

.rating:not(:hover) label input:checked ~ .rck,
.rating:hover label:hover input ~ .rck {
  @apply text-warning opacity-100;
}

.chat-input textarea {
  padding: 1rem 3.5rem;
  border-radius: 4.5rem;
  height: 3.5rem;
  outline: none;
}
.chat-input .bg-skeleton,
.chat-input button {
  top: 0.5rem;
}
.chat-input .bg-skeleton {
  left: 0.5rem;
}
.chat-input button {
  @apply rounded-full;
  right: 0.5rem;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  @apply text-primary;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  @apply stroke-primary;
}

.ql-toolbar.ql-snow {
  @apply h-21.25 shadow shadow-nav-mobile rounded-t-2xl bg-white flex justify-around pt-2;
  z-index: 50;
  border: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.ql-toolbar.ql-snow::after {
  display: none;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
  width: 100%;
  @apply grid grid-cols-5 justify-center;
}
.ql-toolbar.ql-snow .ql-formats > * {
  margin: 0 auto;
  height: 1.75rem;
  width: 1.75rem;
}

.ql-container.ql-snow {
  border: none;
  margin-bottom: 3.5rem;
}
.ql-container.ql-snow .ql-editor {
  padding: 0;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  @apply h-12 bg-grey-light border border-transparent rounded px-4 py-3 duration-500 ease-out outline-none relative text-sm text-current flex items-center;
}
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  @apply pl-0 pr-6;
  line-height: 1;
}
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--multiple .select2-selection__arrow {
  @apply hidden;
}
.select2-container--default .select2-selection--single .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  @apply bg-primary bg-opacity-10 border-primary h-6;
}
.select2-container--default .select2-selection--single .select2-selection__choice .select2-selection__choice__remove,
.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  height: 1.375rem;
  border-right: 0;
  padding-left: 0.5rem;
}
.select2-container--default .select2-selection--single .select2-selection__choice .select2-selection__choice__remove span,
.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove span {
  @apply text-grey;
}
.select2-container--default .select2-selection--single .select2-selection__choice .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover {
  @apply bg-transparent transition-all;
}
.select2-container--default .select2-selection--single .select2-selection__choice .select2-selection__choice__remove:hover span,
.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover span {
  @apply text-danger;
}
.select2-container--default .select2-selection--single .select2-selection__choice .select2-selection__choice__display,
.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__display {
  @apply text-primary;
  line-height: 1.375rem;
  padding-left: 0.25rem;
  padding-right: 0.625rem;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  @apply border-transparent;
}

.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
  @apply bg-white rounded px-2 py-4 border-0 shadow mt-2;
}
.select2-container--open .select2-dropdown--below .select2-search--dropdown,
.select2-container--open .select2-dropdown--above .select2-search--dropdown {
  @apply p-0 shadow mb-2;
}
.select2-container--open .select2-dropdown--below .select2-search--dropdown .select2-search__field,
.select2-container--open .select2-dropdown--above .select2-search--dropdown .select2-search__field {
  @apply rounded bg-grey-light border-0 h-10 outline-none px-4;
}
.select2-container--open .select2-dropdown--below .select2-results__option--selectable,
.select2-container--open .select2-dropdown--above .select2-results__option--selectable {
  @apply rounded p-2;
}
.select2-container--open .select2-dropdown--below .select2-results__option--selectable.select2-results__option--highlighted,
.select2-container--open .select2-dropdown--above .select2-results__option--selectable.select2-results__option--highlighted {
  @apply bg-primary text-white;
}
.select2-container--open .select2-dropdown--below .select2-results__option--selected,
.select2-container--open .select2-dropdown--above .select2-results__option--selected {
  @apply bg-primary bg-opacity-10 text-primary;
}

.collapse__head {
  cursor: pointer;
}
.collapse__head i {
  @apply transition-all;
}
.collapse--expand .collapse__head i {
  transform: rotate(180deg);
}

.dropdown-menu-wrapper {
  @apply relative;
}
.dropdown-menu-wrapper .dropdown-menu-trigger._dmt-rotate {
  @apply transition-all duration-400 ease-bounce;
}
.dropdown-menu-wrapper .dropdown-menu-trigger._dmt-rotate._active {
  transform: rotate(20deg);
}
.dropdown-menu-wrapper .dropdown-menu-trigger._dmt-border {
  @apply transition-all duration-400 ease-bounce border-grey border-0;
}
.dropdown-menu-wrapper .dropdown-menu-trigger._dmt-border._active {
  @apply border-2;
}
.dropdown-menu-wrapper .dropdown-menu {
  @apply bg-grey text-white rounded shadow p-2 absolute -top-1 right-0 z-10 opacity-0 transition-all duration-400 ease-bounce;
  width: 10rem;
  visibility: hidden;
  height: auto;
}
.dropdown-menu-wrapper .dropdown-menu--lg {
  width: 12.5rem;
}
.dropdown-menu-wrapper .dropdown-menu__link {
  @apply block py-2 px-2 rounded font-bold text-sm transition-all ease-out w-full text-left;
}
.dropdown-menu-wrapper .dropdown-menu__link:hover {
  @apply bg-white text-grey;
}
.dropdown-menu-wrapper .dropdown-menu._show {
  @apply top-0 opacity-100;
  visibility: visible;
}

.navbar {
  @apply hidden fixed text-current-light top-0 w-full z-50 transition-all border-b border-solid border-grey-light transition-shadow duration-500 ease-out;
}
@media (min-width: theme("screens.md")) {
  .navbar {
    @apply border-transparent;
  }
}
@media (min-width: theme("screens.lg")) {
  .navbar {
    @apply block;
  }
}
.navbar .container {
  @apply flex items-center justify-between relative h-12;
}
@media (min-width: theme("screens.lg")) {
  .navbar .container {
    @apply h-16;
  }
}
.navbar__left {
  @apply flex items-center flex-1;
}
.navbar__left .story-section-wrapper {
  width: 100%;
  max-width: 18.75rem;
}
.navbar__left .story-section-wrapper .dropdown-menu {
  @apply bg-grey-light text-current-light;
  width: 17.5rem;
  left: 0;
}
.navbar__left .story-section-wrapper .dropdown-menu ._box {
  max-height: 18.75rem;
  overflow: hidden;
  overflow-y: auto;
}
.navbar__left .story-section-wrapper .dropdown-menu__link {
  position: relative;
}
.navbar__left .story-section-wrapper .dropdown-menu__link p {
  width: calc(100% - 2rem);
}
.navbar__left .story-section-wrapper .dropdown-menu__link .text-xs {
  font-weight: normal;
}
.navbar__left .story-section-wrapper .dropdown-menu__link i {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
.navbar__left .story-section-wrapper .dropdown-menu__link:hover {
  @apply bg-primary text-white;
}
.navbar__left .story-section-wrapper .dropdown-menu__link:hover .text-xs span {
  @apply text-white;
}
.navbar__left .story-section {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}
.navbar__left .story-section img {
  width: 1.75rem;
  height: 2.5rem;
  border-radius: 0.125rem;
  object-fit: cover;
  margin-right: 0.5rem;
}
@media (min-width: theme("screens.lg")) {
  .navbar__left .story-section img {
    width: 2rem;
    height: 3rem;
  }
}
.navbar__left .story-section__text {
  text-align: left;
}
.navbar__left .story-section__text span {
  @apply text-current-light;
  font-size: 0.75rem;
}
.navbar__left .story-section__text p {
  @apply text-current;
  font-size: 0.875rem;
  font-weight: bold;
}
.navbar__right {
  @apply flex items-center justify-end;
}
.navbar__right__buttons {
  @apply flex items-center;
}
.navbar__right__buttons > * {
  @apply block relative h-6 text-2xl cursor-pointer;
}
.navbar__right__buttons > * i {
  @apply relative -top-0.5;
}
.navbar__right__buttons > *:not(:last-child) {
  @apply mr-4;
}
.navbar__logo {
  height: 2.5rem;
  margin-right: 2.1875rem;
}
.navbar__logo img {
  @apply h-10;
}
.navbar__backlink {
  @apply h-6 text-2xl mr-4;
}
.navbar__backlink i {
  @apply -top-0.5 relative;
}
.navbar__title {
  @apply block pr-4 font-medium line-clamp-1;
}
.navbar__search {
  @apply flex-1 absolute transform-gpu -translate-y-12 transition-all duration-300 ease-slow-end;
  z-index: 100;
  width: calc(100% - 7.5rem);
  left: 3.5rem;
}
.navbar__search._show {
  @apply translate-y-0;
}
@media (min-width: theme("screens.lg")) {
  .navbar__search {
    @apply relative translate-y-0;
    width: inherit;
    left: 0rem;
  }
}
.navbar__search .input-group .input {
  max-width: 100%;
}
@media (min-width: theme("screens.lg")) {
  .navbar__search .input-group .input {
    max-width: 29.75rem;
  }
}
.navbar .search-area {
  @apply fixed h-full bg-white top-0;
  left: 0;
  width: 100%;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.2s ease-in-out;
}
.navbar .search-area._show {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s ease-in-out;
}
.navbar__cta {
  margin-left: 1.5rem;
  width: 7.75rem;
}
.navbar__bookmark input:checked + i {
  @apply text-primary;
}
.navbar--mobile {
  @apply block;
}
@media (min-width: theme("screens.lg")) {
  .navbar--mobile {
    @apply hidden;
  }
}
.navbar--both {
  @apply block;
}
.navbar--transparent {
  @apply border-transparent text-white;
}
.navbar--transparent .navbar__title {
  @apply text-transparent transition-all;
}
.navbar--transparent .navbar__title.-show {
  @apply text-current-light;
}
.navbar--transparent.-color {
  @apply text-current-light;
}

.navbar-bottom {
  @apply fixed bottom-0 left-0 w-full z-50;
}
@media (min-width: theme("screens.lg")) {
  .navbar-bottom {
    @apply hidden;
  }
}
.navbar-bottom ul {
  @apply h-21.25 shadow shadow-nav-mobile rounded-t-2xl bg-white flex justify-around pt-2;
}
.navbar-bottom ul li > * {
  @apply block text-center flex flex-col;
}
.navbar-bottom ul li > * i {
  @apply text-2xl h-6.5;
}
.navbar-bottom ul li > * span {
  @apply block mt-1 text-xs;
}
.navbar-bottom ul li > * img.avatar {
  @apply mt-0.5 w-6 h-6 rounded-full object-cover mx-auto;
}

.hero__left h1 {
  max-width: 40.5rem;
}
.hero__left__btns .btn {
  width: 100%;
  max-width: 10rem;
}
.hero__left__btns .btn:not(:last-child) {
  margin-right: 0.9375rem;
}
.hero__right img {
  width: 13.6875rem;
}
@media (min-width: theme("screens.md")) {
  .hero__right img {
    width: 25rem;
  }
}
@media (min-width: theme("screens.xl")) {
  .hero__right img {
    width: 31rem;
  }
}

.hero-slider__slider .swiper-slide {
  width: 100%;
  max-width: 18.25rem;
}
.hero-slider__slider__card {
  height: 11.25rem;
  box-shadow: 0px 8px 16px -8px rgba(36, 38, 40, 0.1);
  border-radius: 0.5rem;
}
@media (min-width: theme("screens.md")) {
  .hero-slider__slider .swiper-slide {
    width: 74.378vw;
    max-width: 63.5rem;
  }
  .hero-slider__slider__card {
    height: 30.162vw;
    max-height: 25.75rem;
  }
}
.hero-slider__slider .swiper-pagination {
  bottom: 0;
}
.hero-slider__slider .swiper-arrows {
  top: calc(50% - (2.25rem / 2));
  left: 0;
  transform: translateY(-50%);
}
.hero-slider__slider .swiper-arrows button {
  position: absolute;
}
.hero-slider__slider .swiper-arrows button:first-child {
  left: calc(50% - 42.5vw);
}
.hero-slider__slider .swiper-arrows button:last-child {
  right: calc(50% - 42.5vw);
}
@media (min-width: theme("screens.sm")) {
  .hero-slider__slider .swiper-arrows button {
    @apply transition-all duration-300 ease-out;
  }
  .hero-slider__slider .swiper-arrows button:hover {
    @apply transform-gpu -translate-y-1 shadow-lg opacity-75;
  }
  .hero-slider__slider .swiper-arrows button:active {
    @apply transform-gpu translate-y-0 shadow-sm opacity-100;
  }
}
@media (min-width: 1366px) {
  .hero-slider__slider .swiper-arrows button:first-child {
    left: calc(50% - 35.625rem);
  }
  .hero-slider__slider .swiper-arrows button:last-child {
    right: calc(50% - 35.625rem);
  }
}

.categories .btn {
  @apply mx-auto;
  width: 13.6875rem;
}

.gettings-started .gstarted-step-wrapper {
  @apply relative;
  top: -4rem;
  margin-bottom: -4rem;
}
.gettings-started .btn {
  max-width: 30rem;
}

@media (min-width: theme("screens.lg")) {
  .dashboard {
    @apply flex;
  }
}
.dashboard__left {
  width: 25rem;
}
@media (max-width: theme("screens.lg")) {
  .dashboard__left {
    @apply hidden;
  }
}
@media (min-width: theme("screens.lg")) {
  .dashboard__right {
    @apply flex-1 lg:pl-12;
  }
}
@media (min-width: theme("screens.sm")) {
  .dashboard__links {
    @apply transition-all duration-300 ease-out;
  }
  .dashboard__links:hover {
    @apply pl-2 text-primary;
  }
}
@media (min-width: theme("screens.lg")) {
  .dashboard__card {
    @apply bg-white py-8 px-8 rounded shadow-box flex flex-col;
    min-height: 100%;
  }
}