.hero-slider

    &__slider

        .swiper-slide
            width: 100%
            max-width: toRem(292)

        &__card
            height: toRem(180)
            box-shadow: 0px 8px 16px -8px rgba(36, 38, 40, 0.1)
            border-radius: toRem(8)

        @media (min-width: theme('screens.md')) 

            .swiper-slide
                width: 74.378vw
                max-width: 63.5rem

            &__card
                height: 30.162vw
                max-height: 25.75rem
        
        .swiper-pagination
            bottom: 0

        .swiper-arrows
            top: calc(50% - (2.25rem / 2))
            left: 0
            transform: translateY(-50%)

            button
                position: absolute
                
                &:first-child
                    left: calc(50% - 42.5vw)
                
                &:last-child
                    right: calc(50% - 42.5vw)

                @media (min-width: theme('screens.sm'))
                    @apply transition-all duration-300 ease-out

                    &:hover
                        @apply transform-gpu -translate-y-1 shadow-lg opacity-75
                    &:active
                        @apply transform-gpu translate-y-0 shadow-sm opacity-100
                    
                @media (min-width: 1366px)
                    &:first-child
                        left: calc(50% - 35.625rem)
                    
                    &:last-child
                        right: calc(50% - 35.625rem)

        