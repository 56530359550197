.input-group

    &__label
        @apply block text-sm text-grey pb-1

    &__icon,
    &__icon2
        @apply absolute text-current
        top: 50%
        transform: translateY(-50%)
        font-size: 1.375rem
        z-index: 1

        &--sm
            font-size: 1rem

    sup
        @apply mt-2 block top-0 text-grey
        font-size: .75rem
        line-height: 1

    &--i-left
        .input-group
            &__icon
                left: .9375rem

                &--sm
                    left: .75rem
        
        .input
            padding-left: 2.75rem

            &--sm
                padding-left: 2.375rem

        

    &--i-right
        .input-group
            &__icon
                right: .9375rem

                &--sm
                    right: .75rem
        
        .input
            padding-right: 2.75rem

            &--sm
                padding-right: 2.375rem

    &--i-both
        .input-group
            &__icon2
                right: .9375rem

                &--sm
                    right: .75rem
            &__icon
                left: .9375rem

                &--sm
                    left: .75rem
        
        .input
            padding-right: 2.75rem
            padding-left: 2.75rem

            &--sm
                padding-right: 2.375rem
                padding-left: 2.375rem

    &--err

        .input
            @apply border-danger

        sup
            @apply text-danger

    
label.checkbox
    @apply flex justify-center
    
    span
        

        &::before
            @apply h-6 w-6 rounded-sm

    input[type="checkbox"]
        @apply hidden bg-grey-light

        &:checked
            + span

                &::before
                    @apply bg-primary


                
    