.dashboard

    @media (min-width: theme('screens.lg'))
        @apply flex

    &__left
        width: toRem(400)

        @media (max-width: theme('screens.lg'))
            @apply hidden

    &__right
        @media (min-width: theme('screens.lg'))
            @apply flex-1 lg:pl-12

    &__links
        // Breakpoint
        @media (min-width: theme('screens.sm'))
            @apply transition-all duration-300 ease-out

            &:hover
                @apply pl-2 text-primary
                            
    &__card
        @media (min-width: theme('screens.lg'))
            @apply bg-white py-8 px-8 rounded shadow-box flex flex-col
            min-height: 100%