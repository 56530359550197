.popup
    @apply fixed top-0 left-0 w-full h-full bg-transparent invisible
    transition: all .2s ease-out .2s
    z-index: 999

    &__card
        @apply bg-white absolute w-full
        transition: all .4s ease-out 0s
        bottom: -100%

        @media (min-width: theme('screens.lg'))
            right: 50%
            transform: translate(50%, 50%)
            max-width: toRem(400)

        &__head
            @apply px-5

            ._inner
                @apply py-4 flex justify-between items-center

                &._v2
                    @apply pb-2

                span
                    @apply text-current font-medium

                button

                    i
                        @apply text-2xl

        &__body
            @apply px-5

    &--drawer
        .popup
            &__card
                height: 100vh
                bottom: inherit
                right: -100%

    &--show
        @apply bg-current-light bg-opacity-80 visible
        transition: all .2s ease-out 0s

        .popup
            &__card
                max-height: 70vh
                overflow-y: auto
                bottom: 0%
                transition: all .4s ease-out .1s

                @media (min-width: theme('screens.lg'))
                    bottom: 50%
                    right: 50%
                    max-width: toRem(480)
                    transform: translate(50%, 50%)
                    border-radius: toRem(8)

        &.popup--drawer
            .popup
                &__card
                    max-height: 100vh
                    right: 0%
