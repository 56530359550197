.love-button
    i
        &::before
            @appy transition-all
            content: "\e917"
    input
        &:checked
            + i
                &::before
                    @apply text-danger
                    content: "\e912"
        