.category-card
    background-size: cover
    height: toRem(129)

    @media (min-width: theme('screens.sm'))
        background-size: 100%

        &:hover
            background-size: 110%

    @media (min-width: theme('screens.md'))
        height: toRem(180)

    @media (min-width: theme('screens.lg'))
        background-size: 130%

        &:hover
            background-size: 140%
    
    @media (min-width: theme('screens.xl'))
        background-size: 134%

        &:hover
            background-size: 144%

    &::after
        @apply absolute block w-full h-full top-0 left-0
        content: ''
        background: linear-gradient(0deg, #1E2023 8.14%, rgba(43, 46, 51, 0) 112.79%)

    label
        height: toRem(26)
        line-height: toRem(26)

        @media (min-width: theme('screens.lg'))
            height: toRem(29)
            line-height: toRem(29)

    p
        max-width: toRem(386)