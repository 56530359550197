.popup-share
    @apply pt-7 pb-9 grid grid-cols-4 gap-y-6 gap-x-2

    li
        > *
            @apply block text-center

            img
                @apply h-10 mx-auto mb-2
            
            span
                @apply text-xs block