// Story Header
.story-header
    @apply bg-no-repeat bg-cover bg-center
    height: 38vw
    min-height: toRem(306)
    background-color: #454E65
    background-image: url('../img/cover-orn.png')

    &--detail
        @apply relative

        &::after
            @apply block absolute w-full h-full top-0 left-0
            content: ''
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) -11.11%, rgba(0, 0, 0, 0.61) 22.81%)

// Story Info
.story-info
    @apply relative bg-white rounded-t-3xl
    // margin-top: toRem(224)

    &__cover
        width: toRem(195)
        height: toRem(260)
        top: toRem(-146)
        margin-bottom: toRem(-146)

        @media (min-width: theme('screens.lg'))
            margin-bottom: 0
            top: 0


                
    &__section
    
        &__btn
            grid-template-columns: auto toRem(48)

// Story Part
.story-part
    @apply block py-4 border-b border-solid border-grey border-opacity-20 relative

    &__subtitle
        @apply text-xs
    
    &__title
        @apply font-medium mt-1 pr-5 line-clamp-1 transition-all duration-300

    &::after
        font-family: "Custom-Icon-by-Ryaanst" !important
        speak: never
        font-style: normal
        font-weight: normal
        font-variant: normal
        text-transform: none
        line-height: 1
        -webkit-font-smoothing: antialiased
        -moz-osx-font-smoothing: grayscale

        content: "\e904"

        position: absolute
        right: 0
        top: 50%
        @apply transform -translate-y-1/2 text-2xl transition-all duration-300

    &--unread

        .story-part
            &__title
                @apply text-current

        &::after
            @apply text-current

    // Breakpoint
    @media (min-width: theme('screens.sm'))
        @apply transition-all duration-300 ease-out

        &:hover
            @apply pl-2

            &::after
                @apply text-primary

            .story-part
                &__title
                    @apply text-primary