.otp-input
    @apply border-b-2 border-grey text-primary text-2xl font-bold py-2

    &:focus
        @apply border-primary
    
    &:not(:placeholder-shown)
        @apply border-primary

    &--err
        @apply text-danger

        &:focus
            @apply border-danger
        
        &:not(:placeholder-shown)
            @apply border-danger