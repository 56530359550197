.navbar
    @apply hidden fixed text-current-light top-0 w-full z-50 transition-all border-b border-solid border-grey-light transition-shadow duration-500 ease-out

    @media (min-width: theme('screens.md'))
        @apply border-transparent
    
    @media (min-width: theme('screens.lg'))
        @apply block

    .container
        @apply flex items-center justify-between relative h-12

        @media (min-width: theme('screens.lg'))
            @apply h-16
    
    &__left
        @apply flex items-center flex-1

        .story-section-wrapper
            width: 100%
            max-width: toRem(300)
            
            .dropdown-menu
                @apply bg-grey-light text-current-light
                width: toRem(280)
                left: 0

                ._box
                    max-height: toRem(300)
                    overflow: hidden
                    overflow-y: auto

                &__link
                    position: relative

                    p
                        width: calc(100% - 2rem)

                    .text-xs
                        font-weight: normal
                    
                    i
                        position: absolute
                        top: 50%
                        right: toRem(16)
                        transform: translateY(-50%)
                    
                    &:hover
                        @apply bg-primary text-white

                        .text-xs

                            span
                                @apply text-white

        .story-section
            display: flex
            align-items: center
            padding-right: toRem(16)

            img
                width: toRem(28)
                height: toRem(40)
                border-radius: toRem(2)
                object-fit: cover
                margin-right: toRem(8)

                @media (min-width: theme('screens.lg'))
                    width: toRem(32)
                    height: toRem(48)

            &__text
                text-align: left

                span
                    @apply text-current-light
                    font-size: toRem(12)
                p
                    @apply text-current
                    font-size: toRem(14)
                    font-weight: bold

    &__right
        @apply flex items-center justify-end

        &__buttons
            @apply flex items-center
            > *
                @apply block relative h-6 text-2xl cursor-pointer

                i
                    @apply relative -top-0.5

                &:not(:last-child)
                    @apply mr-4
                    

    &__logo
        height: toRem(40)
        margin-right: toRem(35)

        img
            @apply h-10

    &__backlink
        @apply h-6 text-2xl mr-4

        i
            @apply -top-0.5 relative

    &__title
        @apply block pr-4 font-medium line-clamp-1
    
    &__search
        @apply flex-1 absolute transform-gpu -translate-y-12 transition-all duration-300 ease-slow-end
        z-index: 100
        width: calc(100% - 7.5rem)
        left: toRem(56)

        &._show
            @apply translate-y-0
        
        @media (min-width: theme('screens.lg'))
            @apply relative translate-y-0
            width: inherit
            left: toRem(0)

        .input-group
            .input
                max-width: 100%

                @media (min-width: theme('screens.lg'))
                    max-width: toRem(476)

    .search-area
        @apply fixed h-full bg-white top-0
        left: 0
        width: 100%
        z-index: 99
        visibility: hidden
        opacity: 0
        transition: visibility 0s .3s, opacity .2s ease-in-out


        &._show
            visibility: visible
            opacity: 1
            transition: visibility 0s, opacity .2s ease-in-out

    &__cta
        margin-left: toRem(24)
        width: toRem(124)

    &__bookmark
        input
            &:checked
                + i
                    @apply text-primary
    
    &--mobile
        @apply block

        @media (min-width: theme('screens.lg'))
            @apply hidden

    &--both
        @apply block

    &--transparent
        @apply border-transparent text-white

        .navbar__title
            @apply text-transparent transition-all

            &.-show
                @apply text-current-light

        &.-color
            @apply text-current-light

// Navbar Bottom
.navbar-bottom
    @apply fixed bottom-0 left-0 w-full z-50

    @media (min-width: theme('screens.lg'))
        @apply hidden

    ul
        @apply h-21.25 shadow shadow-nav-mobile rounded-t-2xl bg-white flex justify-around pt-2

        li
            > *
                @apply block text-center flex flex-col

                i
                    @apply text-2xl h-6.5
                
                span
                    @apply block mt-1 text-xs

                img.avatar
                    @apply mt-0.5 w-6 h-6 rounded-full object-cover mx-auto