.chat-input
    textarea
        padding: toRem(16) toRem(56)
        border-radius: toRem(72)
        height: toRem(56)
        outline: none

    .bg-skeleton,
    button
        top: toRem(8)

    .bg-skeleton
        left: toRem(8)

    button
        @apply rounded-full
        right: toRem(8)