.hero-slider-card
    @apply relative overflow-hidden flex flex-col justify-end

    &::before
        @apply block absolute left-0 top-0 w-full h-full
        content: ''
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0.61) 71.8%)

    label
        height: toRem(26)
        line-height: toRem(26)

        @media (min-width: theme('screens.md'))
            height: toRem(32)
            line-height: toRem(32)

    p
        max-width: 70%