.dropdown-menu-wrapper
    @apply relative

    .dropdown-menu-trigger
        
        &._dmt-rotate
            @apply transition-all duration-400 ease-bounce

            &._active
                transform: rotate(20deg) 

        &._dmt-border
            @apply transition-all duration-400 ease-bounce border-grey border-0
            &._active
                @apply border-2
    
    .dropdown-menu
        @apply bg-grey text-white rounded shadow p-2 absolute -top-1 right-0 z-10 opacity-0 transition-all duration-400 ease-bounce
        width: toRem(160)
        visibility: hidden
        height: auto

        &--lg
            width: toRem(200)

        &__link
            @apply block py-2 px-2 rounded font-bold text-sm transition-all ease-out w-full text-left

            &:hover
                @apply bg-white text-grey 

        &._show
            @apply top-0 opacity-100
            visibility: visible


    