.notif-badge

    &::after
        @apply bg-transparent transition-all duration-300 ease-bounce
        content: ''
        display: block
        position: absolute
        width: toRem(0)
        height: toRem(0)
        border-radius: 50%
        transform-origin: center
        top: 0
        right: 0

    &--active

        &::before
            @apply bg-danger animate-ping
            content: ''
            display: block
            position: absolute
            width: toRem(8)
            height: toRem(8)
            border-radius: 50%
            transform-origin: center
            top: 0
            right: 0

        &::after
            @apply bg-danger
            width: toRem(8)
            height: toRem(8)
